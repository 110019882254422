<template>
	<div class="dashboard-nav" :class="{ 'dashboard-nav--scrolled': scrolled }">

		<div class="dashboard-nav__content">
			<router-link :to="{name: 'dashboard' }">
				<img src="~@/assets/logo_dtcc-white.png" alt="DTCC" class="dashboard-nav__logo" width="98" />
			</router-link>

			<div class="dashboard-nav__items">
				<router-link class="dashboard-nav__item" active-class="dashboard-nav__item--active" :to="item.link" :key="key" v-for="(item, key) in navItems" :alt="item.label">{{ item.label }}</router-link>
			</div>

			<a :href="`${publicPath}pdf/DTCC_Annual_Financial_Statements_2020_and_2019.pdf`" target="_blank" v-html="title" class="dashboard-nav__title" >{{title}}</a>
		</div>
	</div>
</template>

<script>

export default {
	name: 'MainNav',
	data: () => ({
		scrolled: false,
		// opened: false,
		title: `2020 \nAnnual \nReport`,
		publicPath: process.env.BASE_URL,
	}),
	computed: {
		navItems() {
			return this.$store.getters['nav/items']
		}
	}
}
</script>

<style scoped lang="scss">

.dashboard-nav {
	display: flex;
	justify-content: center;
	background: linear-gradient(0deg, rgba(0,0,0,0) 25%, rgba(0,0,0,.6) 100%);
	position: absolute;
	width: 100%;
	z-index: 6;

	&__content {
		@include gutter('padding-top', 1.75);
		@include gutter('padding-bottom', 1.75);
		@include layout();
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: $container-max-width;
		//margin: 0 auto;
		width: 100%;
		font-family: $font-family-secondary;

		@include media-query-min-width('desktop') {
			@include gutter('padding-top', 2.5);
			@include gutter('padding-bottom', 2.5);
		}
	}

	&__logo {
		animation: fadeIn 2000ms $ease-out 500ms 1 forwards;
		opacity: 0;
		max-width: 50px;

		@include media-query-min-width('tablet') {
			max-width: 60px;
		}

		@media only screen and (min-width: 1128px) {
			max-width: 60px;
		}

		@include media-query-min-width('desktop') {
			max-width: initial;
		}
	}

	&__items {
		display: none;

		animation: fadeIn 2000ms $ease-out 1000ms 1 forwards;
		opacity: 0;

		@include media-query-min-width('tablet') {
			display: block;
		}
	}

	&__item {
		@include fontSize(15px);
		@include gutter('padding-left', 0.25);
		@include gutter('padding-right', 0.25);
		color: color_('primary', 'text');
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 1px;

		@include media-query-min-width('tablet') {
			@include fontSize(15px);
			@include gutter('padding-left', 0.5);
			@include gutter('padding-right', 0.5);
			letter-spacing: 0px;
		}

		@media only screen and (min-width: 1128px) {
			@include fontSize(18px);
			@include gutter('padding-left', 0.75);
			@include gutter('padding-right', 0.75);
		}

		@include media-query-min-width('desktop') {
			@include gutter('padding-left', 1);
			@include gutter('padding-right', 1);
			@include fontSize(20px);
		}
	}

	&__title {
		@include fontSize(20px);
		color: color_('primary', 'text');
		text-transform: uppercase;
		font-weight: lighter;
		letter-spacing: 1px;

		animation: fadeIn 2000ms $ease-out 1300ms 1 forwards;
		opacity: 0;

		@include media-query-max-width('desktop') {
			@include fontSize(15px);
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
</style>
