<template>
	<div class="text-highlight">
		<div class="text-highlight__content">
			<slot></slot>
		</div>
	</div>
</template>

<script>
// Export
export default {
	name: 'TextHighlight',
}
</script>

<style lang="scss" scoped>

.text-highlight {
	@include gutter('margin-top', 5);
	@include gutter('margin-bottom', 5);
	@include layout();

	&__content {
		max-width: $content-container-md;
		margin-left: auto;
		margin-right: auto;

		@include media-query-min-width('tablet') {
			max-width: $content-container-lg;
		}

		@include media-query-min-width('desktop') {
			max-width: $content-container-lg;
		}
	}

	p {
		@include gutter('margin-top', 1);
		@include fontSize(24px);
		color: color_('primary');
		font-style: italic;
		line-height: 1.5;
	}
}
</style>
