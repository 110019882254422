<template>
	<div class="letterbox">
		<div class="letterbox__container" :style="{backgroundImage: `url(${image})`}" :id="targetId">
			<div class="letterbox__text">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
// Export
export default {
	name: 'Letterbox',
	props: {
		image: String,
		targetId: String,
	},
}
</script>

<style lang="scss" scoped>
$min-height-desktop: 600px;
$min-height-mobile: 450px;

$max-height-desktop: 850px;

.letterbox {
	//@include gutter('margin-top', 2.5);
	@include gutter('margin-bottom', 2.5);
	@include ratio('film');
	margin-left: 16px;
	margin-right: 16px;
	min-height: $min-height-mobile;

	@include media-query-min-width('desktop') {
		min-height: $min-height-desktop;
	}


	&__container {
		@include layout();
		@include gutter('padding', 2.5);
		background: black;
		background-size: cover;
		background-repeat: no-repeat;
		display: flex;
		flex-direction: column;
		justify-content: center;
		overflow: hidden;
		// position: relative;
		width: 100%;

		@include media-query-min-width('desktop') {
			padding-left: $column;
			padding-right: $column;
		}
	}

	&__text {

		> * {
			position: relative;
			z-index: 5;
		}

		figure {
			@include fontSize(20px);
			@include gutter('margin-bottom', .75);
			font-family: $font-family-secondary;
			letter-spacing: 1px;
			color: color_('primary', 'text');
			text-transform: uppercase;
			margin: 0;
			text-shadow: $textshadow-1;
			position: relative;
			z-index: 5;

			@include media-query-min-width('tablet') {
				@include fontSize(24px);
			}

		}

		h2 {
			@include fontSize(56px);
			@include gutter('margin-bottom', 1.5);
			color: color_('primary', 'text');
			font-family: $font-family-secondary;
			font-weight: bold;
			margin-top: 0;
			text-transform: uppercase;
			line-height: .9;
			text-shadow: $textshadow-1;

			@include media-query-min-width('tablet') {
				@include fontSize(70px);
			}

			@include media-query-min-width('desktop') {
				@include fontSize(96px);
			}

		}
	}

	&__button {

	}

	.video-bg {
		width: 100%;
		z-index: 2;
	}
}
</style>
