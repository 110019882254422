<template>
	<div class="view view--dashboard view-dashboard">

		<dashboard-nav class="view-dashbaord__nav" />

		<div class="dashboard__hero-wrapper">
			<hero class="dashboard__hero">
				<figure>Transforming the Industry</figure>
				<h1>Architects of  <br> <span>Innovation</span></h1>
				<!-- <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam <br> nonumy eirmod tempor invidunt ut labore et dolore magna.</p> -->
			</hero>
			<video-bg :sources="[{src: `${publicPath}video/hero.mp4`, type:'video/mp4'}]" />
		</div>

		<div class="dashboard__intro">
			<div class="dashboard__intro-weare">
				<span>We are</span>
				<ul>
					<li>Diverse</li>
					<li>Partnering</li>
					<li>Re-imagining</li>
					<li>Engaging</li>
					<li>Solving</li>
					<li>Collaborating</li>
				</ul>
			</div>
			<div class="dashboard__intro-content">
				<p>As architects of innovation, DTCC is reimagining the marketplace of the future to develop innovative solutions to help clients overcome today’s challenges and seize new opportunities for growth.</p>
			</div>
		</div>

		<h2 class="dashboard__section-heading" id="leadership">Our Leadership</h2>

		<letterbox :image="require(`@/assets/letters/letter-mike.jpg`)" target-id="letter_mike">
			<figure>CEO</figure>
			<h2>Mike <br> Bodson</h2>
			<router-link :to="{name: 'letters', params: {letter: 'mike'} }" class="btn btn--primary" v-hero-click="`#letter_mike`">Read Letter</router-link>
		</letterbox>

		<letterbox :image="require(`@/assets/letters/letter-bob.jpg`)" target-id="letter-robert">
			<figure>chairman</figure>
			<h2>Bob <br> Druskin</h2>
			<router-link :to="{name: 'letters', params: { letter: 'robert'} }" class="btn btn--primary" v-hero-click="`#letter_robert`">Read Letter</router-link>
		</letterbox>

		<content-list :items="corporateGovernance" :cols="3">
			<template slot="header">
				<h2 class="dashboard__section-heading">Corporate Governance</h2>
			</template>
		</content-list>

		<content-list :items="boardOfDirectors" :cols="6" scroll>
			<template slot="header">
				<h2 class="dashboard__section-heading dashboard__section-heading--small">Board of Directors</h2>
			</template>
		</content-list>

		<content-list :items="managementCommittee" :cols="6" scroll>
			<template slot="header">
				<h2 class="dashboard__section-heading dashboard__section-heading--small">Management Committee</h2>
			</template>
		</content-list>

		<hr id="financials" />

		<content-list :items="financialPerformance" :cols="3">
			<template slot="header">
				<h2 class="dashboard__section-heading">Our Financial Performance</h2>
			</template>
		</content-list>

		<hr id="highlights" />

		<h2 class="dashboard__section-heading">Business Highlights</h2>

		<letterbox>
			<h2>Building the Financial Marketplace of Tomorrow</h2>
			<router-link :to="{path: '/business-highlights/what-a-difference-a-night-makes' }" class="btn btn--primary">View Experience</router-link>
			<video-bg :sources="[{src: `${publicPath}video/DTCC_Annual_Report_loop_v04.mp4`, type:'video/mp4'}]" />
		</letterbox>

		<content-list :items="thoughtLeadership" :cols="4" scroll>
			<template slot="header">
				<h2 class="dashboard__section-heading dashboard__section-heading--small">Thought Leadership</h2>
			</template>
		</content-list>

		<content-list :items="awards" :cols="6" scroll class="no-hover">
			<template slot="header">
				<h2 class="dashboard__section-heading dashboard__section-heading--small">Awards & Recognitions</h2>
			</template>
		</content-list>

		<content-list :items="pandemic" :cols="4" scroll>
			<template slot="header">
				<h2 class="dashboard__section-heading">Beyond the Pandemic</h2>
			</template>
		</content-list>

		<hr />

		<letterbox :image="require(`@/assets/letters/letter-mike.jpg`)" target-id="letter_mike" id="innovation">
			<figure>The Future of DTCC</figure>
			<h2>Architects of <br> Innovation</h2>
			<router-link :to="{name: 'stride'}" class="btn btn--primary">Discover Our Transformation</router-link>
			<video-bg :sources="[{src: `${publicPath}video/DTCC_Annual_Report_rocket.mp4`, type:'video/mp4'}]" />
		</letterbox>

		<hr id="people" />

		<content-list :items="powerPeople" :cols="4" scroll>
			<template slot="header">
				<h2 class="dashboard__section-heading">The power of our people</h2>
			</template>
		</content-list>

		<hr />

		<div class="dashboard__logo">
			<logo />
			<advancing />
		</div>

		<site-footer class="dashboard__footer" />

		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<template slot="content">
				[somethign]
			</template>
		</modal>
	</div>
</template>

<script>
import DashboardNav from '@/components/DashboardNav'
import Hero from '@/components/Hero'
import TextHighlight from '@/components/TextHighlight'
import Letterbox from '@/components/Letterbox'
import ContentList from '@/components/ContentList'
import Logo from '@/components/Logo'
import Advancing from '@/components/Advancing'
import SiteFooter from '@/components/SiteFooter'
import Modal from '@/components/Modal'
import VideoBg from '@/components/VideoBg'

import ContentCorporate from '@/assets/content/corporate/filler.json'
import ContentBoard from '@/assets/content/board/filler.json'
import ContentPerformance from '@/assets/content/performance/filler.json'
import ContentThought from '@/assets/content/thought/filler.json'
import ContentBios from '@/assets/content/bios/filler.json'
import ContentAwards from '@/assets/content/awards/content.json'
import ContentPower from '@/assets/content/power/content.json'
import ContentPandemic from '@/assets/content/pandemic/filler.json'

const contentImage = (c, prefix = '', field = 'image') => {
	try {
		c[field] = require(`@/assets${prefix}${c[field]}`)
	} catch (error) {
		// console.warn(error)
		// faily silently
	}
	return c
}

export default {
	name: 'ViewDashboard',
	components: {
		DashboardNav,
		Hero,
		TextHighlight,
		Letterbox,
		ContentList,
		Logo,
		Advancing,
		SiteFooter,
		Modal,
		VideoBg,
	},
	data: () => ({
		publicPath: process.env.BASE_URL,
		corporateGovernance: ContentCorporate.map(e => contentImage(e, `/content/corporate`)),
		boardofDirectors: ContentBoard.map(e => contentImage(e)),
		financialPerformance: ContentPerformance.map(e => contentImage(e, `/content/performance`)),
		thoughtLeadership: ContentThought.map(e => contentImage(e, `/content/thought`)),
		awards: ContentAwards.map(e => contentImage(e, `/content/awards/`)),
		powerPeople: ContentPower.map(e => contentImage(e, `/content/power/`)),
		pandemic: ContentPandemic.map(e => contentImage(e, `/content/pandemic/`)),
		boardOfDirectors: ContentBios.filter(e => e.type.includes(`board`)).map(e => {
			let img = ''
			try {
				img = require(`@/assets/bios/${e.image}`)
			} catch (error) {

			}
			return {
				link: {
					name: 'bios',
					params: {
						person: e.id,
					},
				},
				meta: `Start Reading`,
				content: e.title,
				title: e.name,
				image: img,
			}
			return e
		}),
		managementCommittee: ContentBios.filter(e => e.type.includes(`management`)).map(e => {
			let img = ''
			try {
				img = require(`@/assets/bios/${e.image}`)
			} catch (error) {

			}
			return {
				link: {
					name: 'bios',
					params: {
						person: e.id,
					},
				},
				meta: `Start Reading`,
				content: e.title,
				title: e.name,
				image: img,
			}
		}),
	}),
	computed: {
		modalVisible() {
			return false
		},
		modalTitle() {
			return ''
		},
	},
	mounted() {
	},
	methods: {
		hideModal() {

		},
	},
	metaInfo() {
		return {
			title: "Dashboard",
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler: function (val) {
				const { hash } = val
				if (!hash) return
				const el = document.querySelector(hash)
				if (!el) return

				window.scrollTo({
					top: el.getBoundingClientRect().top,
					left: 0,
					behavior: 'smooth',
				})
			},
		},
	},
}
</script>

<style scoped lang="scss">
.view-dashboard {
	.dashboard {
		&__hero {
			background: linear-gradient(0deg, rgba(0,57,86,1) 0%, rgba(0,57,86,0) 100%);
			// background-image: url('~@/assets/dashboard/dashboard-hero@2x.jpg');
			z-index: 5;
			&-wrapper {
				position: relative;
				overflow: hidden;
			}

			.hero {
				&__text {

					figure {
						@include media-query-min-width('desktop') {
							@include fontSize(48px);
							@include gutter('margin-bottom', 1);
						}
					}

					h1 {
						@include media-query-min-width('desktop') {
							@include fontSize(128px);
						}
					}
				}
			}
		}

		&__intro {
			@include layout();
			@include gutter('padding-top', 2);
			@include gutter('padding-bottom', 1);
			align-items: flex-start;
			display: flex;
			flex-direction: column-reverse;

			@include media-query-min-width('tablet') {
				@include gutter('padding', 3);
				flex-direction: row;
			}

			@include media-query-min-width('desktop') {
				@include gutter('padding', 6);
				@include gutter('padding-bottom', 4);
			}

			&-weare {
				@include gutter('margin-left', 2);
				display: flex;
				position: relative;
				margin-top: 30px;

				@include media-query-min-width('tablet') {
					margin-top: 10px;
				}

				span {
					@include fontSize(12px);
					display: block;
					text-transform: uppercase;
					color: color_('text', 'light');
					letter-spacing: 2px;
					margin: 0;
					transform: rotate(-90deg);
					transform-origin: left bottom;
					white-space: nowrap;
					left: -30px;
					top: 35px;
					position: absolute;

					@include media-query-min-width('tablet') {
						left: -35px;
						top: 44px;
					}
				}

				ul {
					margin: 0;
					padding: 0;
					list-style-type: none;

					li {
						margin-bottom: 5px;
					}
				}
			}

			&-content {
				@include fontSize(26px);
				line-height: 1.5;

				@include media-query-min-width('tablet') {
					@include gutter('padding-left', 4);
				}

				@include media-query-min-width('desktop') {
					@include gutter('padding-left', 7);
				}

				p {
					margin: 0;
				}
			}
		}


		&__section-heading {
			@include fontSize(40px);
			@include layout();
			color: color_('primary');
			font-family: $font-family-secondary;
			font-weight: bold;
			letter-spacing: 1px;
			text-transform: uppercase;

			&--small {
				@include fontSize(24px);
				@include layout();
				color: color_('primary','dark');
			}
		}

		&__logo {
			@include layout();
			@include gutter('padding-top', 2.5);
			@include gutter('padding-bottom', 3.5);
			display: flex;
			justify-content: center;

			@include media-query-max-width('phone') {
				left: -20%;
				position: relative;
			}
		}
	}

	hr {
		@include gutter('margin-top', 0);
		@include gutter('margin-bottom', 2.5);
		border-top: 1px solid color_('background','light');
		border-bottom: 0;
	}
}
</style>
