<template>
	<div class="logo">
		 <div class="circles">
			<div class="circle-top">
				<img src="@/assets/circle-top.png" />
			</div>
			<div class="circle-bottom">
				<img src="@/assets/circle-bottom.png" />
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">

.circles {

	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	// width: 500px;
	height: 50vh;
	position: relative;
	-ms-flex-pack: center;

	.mobile & {
		background: color_('background', 'dark');
		height: auto;
	}
}

.circle-top,
.circle-bottom {
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	// position: absolute;
	// left: 0;
	// max-width: 500px;
	// width: 100%;
	// height: 100%;
	// @include transition(all, ease-in-out, 1000ms);
	opacity: 0;

	img {
		max-height: 100%;
		width: 250px;

		.mobile & {
			width: 150px;
		}
	}
}

.circle-top {
	// transform: translateY(25%);
	// top: 0;
	// background-image: url(~@/assets/circle-top.png);
	background-size: cover;
	animation: slideUp 1000ms $ease-out 500ms 1 forwards;
}

.circle-bottom {
	// transform: translateY(-25%);
	// bottom: 0;
	// background-image: url(~@/assets/circle-bottom.png);
	background-size: cover;
	animation: slideDown 1000ms $ease-out 500ms 1 forwards;
}


@keyframes slideUp {
	0% {
		opacity: 0;
		transform: translateY(50%);
	}

	100% {
		opacity: 1;
		transform: translateY(25%);
	}
}

@keyframes slideDown {
	0% {
		opacity:0;
		transform: translateY(-50%);
	}

	100% {
		opacity: 1;
		transform: translateY(-25%);
	}
}
</style>
