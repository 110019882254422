<template>
	<div class="advancing">
		<div class="advancing__intro">
			<span>Advancing</span>
			<span>Financial</span>
			<span>Markets.</span>
			<span class="text-primary">Together.</span>
		</div>
	</div>
</template>

<script>


export default {
	name: 'Advancing'
}
</script>

<style scoped lang="scss">
$delay: 250ms;

.advancing {
	align-items: center;
	display: flex;
	flex-wrap: nowrap;

	-ms-flex-pack: center;

	@include ieOnly() {
		flex: 1 0 auto;
	}

	@include breakpoint('mobile') {
		flex-direction: column;
	}

	.text-primary {
		color: color_('primary');
	}

	&__intro {
		color: color_('text');
		font-family: $font-family-secondary;
		@include fontSize(90px);
		font-weight: bold;
		line-height: 1;
		text-transform: uppercase;
		padding-left: 5vw;

		@media screen and (max-height: 780px) and (min-width: 768px) {
			zoom: 90%;
		}

		span {
			animation: fadeUp 1000ms $ease-out 500ms 1 forwards;
			opacity: 0;
			display: block;

			@include breakpoint('mobile') {
				opacity: 1;
			}

			&.text-secondary {
				color: color_('secondary');
			}

			&:nth-of-type(1) {
				animation-delay: ($delay * 1.5);
			}

			&:nth-of-type(2) {
				animation-delay: ($delay * 2);
			}

			&:nth-of-type(3) {
				animation-delay: ($delay * 2.5);
			}

			&:nth-of-type(4) {
				animation-delay: ($delay * 3);
			}
		}
	}


}
@keyframes fadeUp {
	0% {
		opacity: 0;
		transform: translateY(50%);
	}

	80% {
		opacity: 1;
	}

	100% {
		opacity: 1;
		transform: translateY(0%);
	}
}
</style>
