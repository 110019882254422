<template>
	<div class="content-list" :class="{'content-list--scroll': scroll }">
		<div class="content-list__header" v-if="hasHeader">
			<slot name="header"></slot>
		</div>

		<div class="content-list__scroll-control content-list__scroll-control--left" v-if="scroll && !atStart">
			<div class="content-list__scroll-btn" @click="doScroll(-1)">
				<svg xmlns="http://www.w3.org/2000/svg" width="15.207" height="12.024" viewBox="0 0 15.207 12.024">
					<g id="Group_194" data-name="Group 194" transform="translate(-17.5 -17.988)">
						<path id="Path_392" data-name="Path 392" d="M-1283,842.045h14" transform="translate(1300.502 -818.045)"  stroke-width="1"/>
						<path id="Path_391" data-name="Path 391" d="M-4461-1799v8h8" transform="translate(-1855.977 -4396.83) rotate(-135)" fill="none" stroke-width="1"/>
					</g>
				</svg>
			</div>
		</div>

		<div class="content-list__list">
			<div class="content-list__list-items" :class="'content-list__list-items--col-' + cols">
				<div
					class="content-list__item"
					:key="key"
					v-for="(item, key) in items"
				>
					<component
						class="content-list__link"
						:is="item.link && item.link.name ? 'router-link' : 'a'"
						:to="item.link && item.link.name ? item.link : undefined"
						:href="item.link && item.link.name ? undefined : item.link"
						:target="item.link && item.link.name ? undefined : '_blank'"
						v-hero-click="item.link && item.link.name ? undefined : 'disable'"
						>
						<div class="content-list__image" :style="{ backgroundImage: 'url(' + item.image + ')' }"></div>
						<div class="content-list__meta">{{item.meta}}</div>
						<div class="content-list__title" v-html="item.title">{{item.title}}</div>
						<div class="content-list__content" v-html="item.content">{{item.content}}</div>
					</component>
				</div>
			</div>
		</div>

		<div class="content-list__scroll-control content-list__scroll-control--right" v-if="scroll && !atEnd">
			<div class="content-list__scroll-btn" @click="doScroll(1)">
				<svg xmlns="http://www.w3.org/2000/svg" width="15.207" height="12.024" viewBox="0 0 15.207 12.024">
					<g id="Group_194" data-name="Group 194" transform="translate(-17.5 -17.988)">
						<path id="Path_392" data-name="Path 392" d="M-1283,842.045h14" transform="translate(1300.502 -818.045)"  stroke-width="1"/>
						<path id="Path_391" data-name="Path 391" d="M-4461-1799v8h8" transform="translate(-1855.977 -4396.83) rotate(-135)" fill="none" stroke-width="1"/>
					</g>
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
import { Flipper, Flipped } from 'vue-flip-toolkit'
import { gsap } from 'gsap'


export default {
	name: 'ContentList',
	components: {
		Flipper,
		Flipped,
	},
	props: {
		items: {
			type: [Array, Object],
			default: () => ([]),
		},
		scroll: Boolean,
		cols: {
			type: Number,
			default: 4
		},
	},
	data: () => ({
		scroller: null,
		scrollItems: null,
		atStart: false,
		atEnd: false,
		pg: 0,
		pageCount: 0,
	}),
	computed: {
		hasHeader() {
			return Boolean(this.$slots.header)
		},
		scrolled() {
			return false
		}
	},
	mounted() {
		this.scroller = this.$el.querySelector('.content-list__list')
		this.scrollItems = this.scroller.querySelector('.content-list__list-items')

		const children = Array.prototype.slice.call(this.scrollItems.children)
		let wrapperWidth = this.scroller.getBoundingClientRect().width
		let childWidth = children[0].getBoundingClientRect().width
		let totalWidth = children.reduce((a, b) => a + (b.getBoundingClientRect().width || 0), 0) + (4 * (this.scrollItems.children.length - 1))

		this.pageCount = Math.ceil(totalWidth / this.scroller.getBoundingClientRect().width) - 1

		// console.log('links', this.$el.querySelectorAll('.content-list__link'))

		// const links = this.$el.querySelectorAll('.content-list__link')
		// for (const link of links) {
		// 	console.log('link', link, Flip.getState(link))
		// }
		// console.log( totalWidth, this.pageCount )

	},
	methods: {
		setFlip(evt) {
			const rect = evt.target.getBoundingClientRect()
			const tl = gsap.timeline()
console.log('contentList.setFlip', evt, rect)
			evt.target.dataset.flipId = "flip"
			evt.target.style.left = rect.left + 'px'
			evt.target.style.top = rect.top + 'px'
			evt.target.style.height = rect.height + 'px'
			evt.target.style.width = rect.width + 'px'
			this.$nextTick(() => {
				tl.to(evt.target, {
					duration: 0.45,
					width: '100vw',
					height: '100vh',
					position: 'fixed',
					top: 0,
					left: 0,
					zIndex: 99,
					ease: 'circ.out',
				})
				// evt.target.classList.add('transition-item')
			})
			// this.$store.dispatch('nav/setNavFrom', evt.target)
		},
		doScroll(dir = 1) {
			const bounds = this.scroller.getBoundingClientRect()
			const children = Array.prototype.slice.call(this.scrollItems.children)
			const wrapperWidth = bounds.width
			const childWidth = children[0].getBoundingClientRect().width
			const childPer = Math.floor(wrapperWidth / childWidth)



			this.pg = Math.max(0, Math.min((this.pageCount), (this.pg + dir)) )
			// let to = (childPer * childWidth * this.pg)
			// to = Math.min( to, (childWidth * children.length) - to) -100
			let to = (bounds.width * this.pg)

			this.scroller.scrollTo({
				top: 0,
				left: to,
				behavior: 'smooth',
			})


			// setTimeout(() => {
			// 	if (this.pg <= 0) {
			// 		this.atStart = true
			// 	} else {
			// 		this.atStart = false
			// 	}

			// 	if (this.pg >= this.pageCount) {
			// 		this.atEnd = true
			// 	} else {
			// 		this.atEnd = false
			// 	}
			// }, 1050)

		},
	},
}
</script>

<style scoped lang="scss">
.content-list {
	$self: &;
	@include layout();
	position: relative;

	&__header {
		//margin-left: -16px;
	}

	&--scroll {
		#{$self}__list {
			overflow-x: auto;
			margin-top: -1.7rem;
			padding-top: 1.7rem;
			scroll-snap-type: x mandatory;

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	&__item {
		@include gutter('margin-bottom', 1.5);
		scroll-snap-align: end;


		&:hover {
			#{$self}__image {
				box-shadow: $hover-box-shadow;
				transform: scale(1.1);
				z-index: 1;

				#{$self}.no-hover & {
					transform: scale(1);
					box-shadow: $initial-box-shadow;
				}
			}
		}

		#{$self}.no-hover & {
			pointer-events: none;
		}
	}

	&__link {
		position: relative;
		display: flex;
		flex-direction: column;
	}

	&__image {
		@include gutter('margin-bottom', .75);
		background-size: cover;
		background-position: center;
		background-repeat: none;
		box-shadow: $initial-box-shadow;
		transform: scale(1);
		transition: box-shadow 150ms $ease-standard, transform 150ms $ease-standard;
		width: 100%;
	}

	&__meta {
		@include fontSize(12px);
		@include gutter('margin-bottom', .4);
		color: color_('text', 'medium');
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 1px;

	}

	&__title {
		@include fontSize(18px);
		@include gutter('margin-bottom', .25);
		color: color_('text');
		font-weight: 700;
	}

	&__content {
		@include fontSize(14px);
		color: color_('text', 'light');
	}

	// &__list-items {
	// 	@include gutter('margin-bottom', 2.5);
	// 	display: grid;
	// 	//grid-template-columns: 1fr;
	// 	grid-column-gap: 4px;
	// 	position: relative;
	// 	scroll-snap-type: proximity;
	// 	scrollbar-width: none;
	// 	overflow-x: scroll;


	// 	&--col-2 {
	// 		//grid-template-columns: 1fr;

	// 		@include media-query-min-width('tablet') {
	// 			grid-template-columns: 1fr 1fr;
	// 		}

	// 		#{$self}__image {
	// 			@include ratio('two');
	// 		}
	// 	}

	// 	&--col-3 {
	// 		//grid-template-columns: 1fr;

	// 		@include media-query-min-width('tablet') {
	// 			grid-template-columns: repeat(3, minmax(0, 1fr));
	// 		}

	// 		#{$self}__image {
	// 			@include ratio('three');
	// 		}
	// 	}

	// 	&--col-4 {
	// 		//grid-template-columns: 1fr;

	// 		@include media-query-min-width('tablet') {
	// 			grid-template-columns: repeat(4, minmax(0, 1fr));
	// 		}

	// 		#{$self}__image {
	// 			@include ratio('four');
	// 		}
	// 	}

	// 	&--col-6 {
	// 		//grid-template-columns: 1fr;

	// 		@include media-query-min-width('tablet') {
	// 			grid-template-columns: 1fr;
	// 			grid-auto-flow: column;
	// 		}

	// 		#{$self}__image {
	// 			@include ratio('square');
	// 		}
	// 	}
	// }

	&__list {
		position: relative;
	}


	&__list-items {
		@include gutter('margin-bottom', 1.25);
		display: grid;
		//grid-template-columns: 1fr;
		grid-auto-flow: column;
		grid-column-gap: 4px;
		position: relative;

		&--col-2 {
			#{$self}__image {
				@include ratio('two');
			}

			#{$self}__item {
				width: 100%;

				@include media-query-min-width('tablet') {
					width: 100%;
				}
			}
		}

		&--col-3 {
			grid-template-columns: repeat(3, 1fr);

			#{$self}__image {
				@include ratio('three');
			}

			#{$self}__item {
				width: 100%;

				@include media-query-min-width('tablet') {
					width: 100%;
				}
			}
		}

		&--col-4 {
			#{$self}__image {
				@include ratio('four');
			}

			#{$self}__item {
				width: 128px;

				@include media-query-min-width('tablet') {
					width: 326px;
				}
			}
		}

		&--col-6 {
			#{$self}__image {
				@include ratio('square');
			}

			#{$self}__item {
				width: 128px;

				@include media-query-min-width('tablet') {
					width: 216px;
				}
			}
		}

		#{$self}--scroll & {
			// scroll-snap-type: x proximity;
			// scrollbar-width: none;
			// overflow-x: auto;
		}
	}

	&__scroll-control {
		display: none;

		@include media-query-min-width('desktop') {
			display: block;
			z-index: 2;
			position: absolute;
			top: 30%;

			&--left {
				left: -20px;
				transform: rotate(-180deg);
			}

			&--right {
				right: -20px;
			}
		}
	}

	&__scroll-btn {
		height: 48px;
		width: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 200px;
		z-index: 1;
		background: #fff;
		box-shadow: 0 0 8px rgba(color_('primary', 'dark'), .2);
		cursor: pointer;
		color: color_('primary', 'dark');
		transition: all $transition-1;

		svg {
			stroke: color_('primary', 'dark');
			fill: color_('primary', 'dark');
			transition: all $transition-1;
		}

		&:hover {
			background-color: color_('primary', 'dark');

			svg {
				stroke: #fff;
				fill: #fff;
			}
		}
	}
}
</style>
